<template>
  <div class="shivuk-rosman">
      <div class="shivuk-rosman-menu">
           <div class="option" @click="state='ManageBranches'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול סניפים</p>
            </div>
           <div class="option" @click="state='ManageSuppliers'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול ספקים</p>
            </div>
           <div class="option" @click="state='ManageHrCompanies'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול חברות כח אדם</p>
            </div>
           <div class="option" @click="state='OfficeEquipmentsManage'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול ציוד משרדי</p>
            </div>
           <div class="option" @click="state='SalesCatalogManagment'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול קטלוג מבצעים</p>
            </div>
           <div class="option" @click="state='ManageErrorsServicesList'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול רשימת קריאות שירות</p>
            </div>
      </div>
      <div class="shivuk-rosman-main">
          <component :is="state" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { defineAsyncComponent } from '@vue/runtime-core'
export default {
    components:{
        ManageBranches:defineAsyncComponent(()=>import('../../components/Admin/Shivuk_Rosman/ManageBranches.vue')),
        ManageSuppliers:defineAsyncComponent(()=>import('../../components/Admin/Shivuk_Rosman/ManageSuppliers.vue')),
        ManageHrCompanies:defineAsyncComponent(()=>import('../../components/Admin/Shivuk_Rosman/ManageHrCompanies.vue')),
        OfficeEquipmentsManage:defineAsyncComponent(()=>import('../../components/Admin/Shivuk_Rosman/OfficeEquipmentsManage.vue')),
        SalesCatalogManagment:defineAsyncComponent(()=>import('../../components/Admin/Shivuk_Rosman/SalesCatalogManagment.vue')),
        ManageErrorsServicesList:defineAsyncComponent(()=>import('../../components/Admin/Shivuk_Rosman/ManageErrorsServicesList.vue')),
    },
    setup(){
        const state = ref(null)

        return{state}
    }
}
</script>

<style scoped>
    .shivuk-rosman{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 375px calc(100% - 375px);
        grid-template-rows: 100%;
    }
    .shivuk-rosman-menu{
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        overflow-y: auto;
        padding: 5px;
    }
    .shivuk-rosman-main{
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    .option{
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 26px;
        background: var(--secondary);
        padding: 0 5px 0 0;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 5px;
        flex-shrink: 0;
    }
    .option:hover p{
        margin-right:25px;
        color: var(--yellow);
    }
    .option i {
        margin-left: 25px;
        color: var(--light);
        font-size: 33px;
    }
    .option p{
        color:var(--light);
        transition: 0.25s;
    }
    @media only screen and (max-width: 800px) {
        .shivuk-rosman{
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows:  150px calc(100% - 150px);
        }
    }
</style>